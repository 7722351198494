<template>
    <v-app>
        <router-view>
        </router-view>
    </v-app>
</template>

<style>
@import './style.css';
</style>

<script>

export default {
    name: 'App',
    components: {},
    data: () => ({}),
    mounted() {},
    methods: { }
};
</script>